<template>
  <div class="right_box" v-if="!this.startAccountPeriod">
    <el-button type="primary" @click="download()" size="small">导出</el-button>
    <el-button size="small" @click="printingNew()" type="success">打印</el-button>
  </div>
  <div>
    <el-radio-group v-model="expStatus">
      <el-radio :label="true">使用公式</el-radio>
      <el-radio :label="false">不使用公式</el-radio>
    </el-radio-group>
  </div>
  <div class="big_box4">
    <div class="top_title3">
      <h4>财产和行为税纳税申报表</h4>
      <h5>一般纳税人、小规模纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center"></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0" width="100%">
      <div class="add" @click="handleAddItem(info)">
        <i class="iconfont icon-jiahao"></i>
      </div>
      <tr class="top_bg">
        <td class="center weight" style="width:3%;">序号</td>
        <td class="center weight" style="width:4%;">税种</td>
        <td class="center weight" style="width:6%;">应税凭证数量</td>
        <td class="center weight" style="width:10%;">税目</td>
        <td class="center weight" style="width:5%;">子目</td>
        <td class="center weight" style="width:8%">税款所属期起</td>
        <td class="center weight" style="width:8%;">税款所属期止</td>
        <td class="center weight" style="width:8%;">计税依据</td>

        <td class="center weight" style="width:10%">应纳税凭证书立（领受）日期</td>
        <td class="center weight" style="width:6%;">税率</td>
        <td class="center weight" style="width:8%;">应纳税额</td>
        <td class="center weight" style="width:8%;">减免税额</td>
        <td class="center weight" style="width:8%;">已缴税额</td>
        <td class="center weight" style="width:8%;">应补（退）税额</td>
      </tr>

      <tr v-for="(item,index) in info" :key="index" class="close_item">
        <td class="center">{{index + 1}}</td>
        <td>
          <el-select @change="changeValue(item)" v-model="item.taxCategory" placeholder="请选择" size="small" style="width:150px">
            <el-option v-for="item in szOptions" :key="item.value" :label="item.label" :value="item.value"  size="small" ></el-option>
          </el-select>
        </td>
        <td><input type="text" v-model="item.yspzsl"></td>
        <td>
          <el-select v-model="item.taxItem" placeholder="请选择" size="small" v-if="item.options.length > 0">
            <el-option v-for="item1 in item.options" :key="item1.value" :label="item1.label" :value="item1.value" ></el-option>
          </el-select>
          <input type="text" v-model="item.taxItem" v-else>
        </td>
        <td><input type="text" v-model="item.taxSub"></td>
        <td>
          <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="item.taxStart" type="date" placeholder="选择日期时间" style="width:100%"></el-date-picker>
        </td>
        <td>
          <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="item.taxEnd" type="date" placeholder="选择日期时间" style="width:100%"></el-date-picker>
        </td>
        <td><input type="text" v-model="item.jsyj" @change="changeList()"></td>
        <td>
          <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="item.ynspzlsrq" type="date" placeholder="选择日期时间" style="width:100%"></el-date-picker>
        </td>
        <qzf-str-td :disabled="false" v-model:amount="item.sl" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus"  v-model:amount="item.ynse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount="item.jmse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount="item.yjse" @change="changeList()"></qzf-str-td>
        <td v-if="expStatus" :disabled="true" style="background-color:#efefef" align="center"><span>{{item.ybtse}}</span></td>
        <qzf-str-td v-else v-model:amount="item.ybtse" @change="changeList()"></qzf-str-td>
        <div class="close" @click="handleDelItem(info,index)">
          <i class="iconfont icon-shanchu"></i> 
        </div>
      </tr>
      <tr>
        <td>--</td>
        <td>合计</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>{{totalAmount}}</td>
        <td>{{totalAmount2}}</td>
        <td>{{totalAmount3}}</td>
        <td>{{totalAmount4}}</td>
      </tr>
    </table>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc') && !source">
    <el-button @click="saveMain" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
    <qzf-button @success="saveMain" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit,taxCategoryList} from '@/api/taxCalculation.js'
import { report ,exportReport} from "@/api/printSet"

export default {
  name:'mainTable',
  props: {
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  computed:{
    totalAmount() {
      let totalAmount = 0;
      this.info.map(v => {
        if (v.ynse) {
          totalAmount += Number(v.ynse);
        }
      });
      return parseFloat(totalAmount.toFixed(2))
    },
    totalAmount2() {
      let jnseAmount = 0;
      this.info.map(v => {
        if (v.jmse) {
          jnseAmount += Number(v.jmse);
        }
      });
      return parseFloat(jnseAmount.toFixed(2))
    },
    totalAmount3() {
      let yjseAmount = 0;
      this.info.map(v => {
        if (v.yjse) {
          yjseAmount += Number(v.yjse);
        }
      });
      return parseFloat(yjseAmount.toFixed(2))
    },
    totalAmount4() {
      let ybtseAmount = 0;
      this.info.map(v => {
        if (v.ybtse) {
          ybtseAmount += Number(v.ybtse);
        }
      });
      return parseFloat(ybtseAmount.toFixed(2))
    },
  },
  data(){
    return {
      expStatus:true,
      szOptions:[
        {
          value:"城镇土地使用税",
          label:"城镇土地使用税"
        },
        {
          value:"房产税",
          label:"房产税"
        },
        {
          value:"印花税",
          label:"印花税"
        },
        {
          value:"资源税",
          label:"资源税"
        },
        // {
        //   value:"环境保护税",
        //   label:"环境保护税"
        // },
      ],
      info: [{
        // type:1,
        taxCategory:'',
        taxItem:'',
        taxStart:'',
        taxEnd:'',
        jsyj:'',
        sl:'',
        ynse:'',
        jmse:'',
        yjse:'',
        ybtse:'',
        options:[]
      }],
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_deed']
      },
      codeFgs:"",
      categoryList:[]
    }
  },
  created() {
    this.getList()
  },
  methods:{
    changeList(){
      if(!this.expStatus){
        return
      }
      this.info.map(v=>{
        if(v.taxCategory == '印花税'){
          if(v.hdbl != 0){
            v.ynse = String((Number(v.jsyj)* Number(v.hdbl) * Number(v.sl)).toFixed(2))
          }else{
            v.ynse = String((Number(v.jsyj) * Number(v.sl)).toFixed(2))
          }
          if(this.codeFgs == '是'){
            if(v.taxItem == '资金账簿'){
              v.jmse = String((Number(v.ynse)/2).toFixed2())
            }else{
              v.jmse = ''
            }
          }else{
            if(v.taxItem == '资金账簿'){
              v.jmse = String(((Number(v.ynse)-(Number(v.ynse)/2)/2)).toFixed(2))
            }else{ 
              // v.jmse = String((Number(v.ynse)/2/10).toFixed2()*10)
              // v.jmse = String(String(((((Number(v.ynse)*1000)/2)/100)).toFixed(1))/10)
              v.jmse = String((Number(v.ynse)/2).toFixed2())
            }
          }
          v.ybtse = String((Number(v.ynse)-Number(v.jmse)).toFixed(2))
        }
      })
    },
    // 主表添加一行
    handleAddItem(item) {
      //console.log(item);
      item.push({
        // type:0,
        taxCategory:'',
        taxItem:'',
        taxStart:'',
        taxEnd:'',
        jsyj:'',
        sl:'',
        ynse:'',
        jmse:'',
        yjse:'',
        ybtse:'',
        options:[]
      });
    },
    // 主表删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    changeValue(e) {
      e.options = []
      this.categoryList.forEach(v=>{
        if(v.collectionProject == e.taxCategory && v.collectionItems){
          e.options.push({
            label:v.collectionItems,
            value:v.collectionItems
          })
        }
      })
    },
    async getList(){
      let id = this.comId ? this.comId : this.$store.getters['user/comInfo'].comId
      await taxCategoryList({comId:id}).then(res=>{
        if(res.data.msg == 'success'){
          this.categoryList = res.data.data.list
        }
      })
      let that = this
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_deed',
          period:this.startAccountPeriod,
          itemName:'main',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.info = res.data.data.info?res.data.data.info:this.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.codeFgs = res.data.data.codeFgs
            this.info?.map(v=>{
              that.changeValue(v)
            })
          }
        })
      }else{
        gsInfo({tableName: 'gs_deed',itemName:'main'}).then(res=>{
          this.info = res.data.data.info?res.data.data.info:this.info
          this.codeFgs = res.data.data.codeFgs
          this.info?.map(v=>{
            that.changeValue(v)
          })
        })
      }
    },
    saveMain(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_deed',
          itemName:'main',
          items:this.info,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        param.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_deed',
          itemName:'main',
          items:this.info
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.width_mini{
  width:4%;
}
.width_small{
  width: 20%;
}
.width_big{
  width: 32%;
}
.width_date{
  width: 10%;
}
.save_box{
  width: 98%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 9px;
    font-size: 20px;
    color: #f15a24;
    cursor: pointer;
    i{
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--themeColor,#17a2b8);
    }
  }
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 13px;
  }
}
.close_item{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 0;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--themeColor,#17a2b8);
    }
  }
}

.big_box4{
  width: 98%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}

</style>
