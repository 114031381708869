<template>
  <div class="big_box4">
    <div class="top_title3">
      <h4>应税服务减除项目清单</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <el-scrollbar :height="contentStyleObj">
      <table class="content" cellspacing="0">
        <i @click="handleAddItem(list)" class="iconfont icon-jiahao addi"></i>
        <tr class="top_bg">
          <td class="center" style="width:4%;">序号</td>
          <td style="width:38%;">开票方纳税人识别号</td>
          <td style="width:20%;">开票方单位名称</td>
          <td style="width:10%;">服务项目名称</td>
          <td style="width:10%;">凭证种类</td>
          <td style="width:10%;">凭证号码</td>
          <td style="width:8%;">金额</td>
        </tr>
        <tr v-for="(item,index) in list" :key="index" class="close_item">
          <td style=" text-align: center;">{{index + 1}}</td>
          <td><input type="text" v-model="item.kpfnsrsbh"></td>
          <td><input type="text" v-model="item.kpfdwmc"></td>
          <td><input type="text" v-model="item.fwxmmc"></td>
          <td><input type="text" v-model="item.pzzlDm1"></td>
          <td><input type="text" v-model="item.pzhm2"></td>
          <qzf-str-td v-model:amount.number="item.je"></qzf-str-td>
          <div class="close" @click="handleDelItem(list,index)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>

        <tr>
          <td class="center" colspan="2">合计</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="amount right">{{ $comdify(amount) }}</td>
        </tr>
      </table>
    </el-scrollbar>
    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button type="primary" @click="saveValues()" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'culturalFb1',
  props: {
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: [],
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      contentStyleObj:{}
    }
  },
  computed:{
    amount(){
      let num = 0
      this.list.forEach(item=>{
        num += item.je*1
      })
      return num.toFixed(2)
    },
  },
  created() {
    this.contentStyleObj = this.$getHeight(275)
    this.getList()
  },
  methods: {
    handleAddItem(item) {
      item.push({
        kpfnsrsbh:'',
        kpfdwmc:'',
        fwxmmc:'',
        pzzlDm1:'',
        pzhm2:'',
        je:0,
      });
    },
    // 主表删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_whsyjsfsbbygz',
          itemName:'fb1',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          this.durationOfTaxTime = res.data.data.time
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }else{
        gsInfo({tableName: 'gs_whsyjsfsbbygz',itemName:'fb1'}).then(res=>{
          this.durationOfTaxTime = res.data.data.time
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_whsyjsfsbbygz',
          itemName:'fb1',
          items: this.list,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_whsyjsfsbbygz',
          itemName:'fb1',
          items: this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  width: 97%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  width: 96%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    color: #333;
    font-size: 13px;
    padding:0 6px;
    input{
      width: 98%;
      line-height: 26px;
      font-size: 13px;
    }
  }
  input{
    width: 100%;
    line-height: 26px;
    font-size: 13px;
  }
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 0;
  right: 3px;
  
}
.addi{
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: -28px;
  font-size: 22px;
  color: var(--themeColor,#17a2b8);
}
.close_item{
  position: relative;
  .close {
    width: 1em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -26px;
    top: 0;
    font-size: 20px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.amount{
  padding-right:20px !important;
}
</style>