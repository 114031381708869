<template>
  <div style="position: relative;">
    <div class="right_box" v-if="!this.paramCheck.comId">
      <el-button size="small" @click="printingNew()" type="success">打印</el-button>
      <el-button type="primary" @click="download()" size="small" plain>导出</el-button>
    </div>
    <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="文化事业建设费申报表">
        <culturalMain :startAccountPeriod="startAccountPeriod" :comId="comId"/>
      </el-tab-pane>
      <el-tab-pane label="应税服务减除项目清单">
        <culturalFb1 :startAccountPeriod="startAccountPeriod" :comId="comId"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import culturalMain from './culturalMain.vue'
  import culturalFb1 from './culturalFb1.vue'
  import { report ,exportReport } from "@/api/printSet"

  export default {
    name: 'culturalTax',
    components: { culturalMain,culturalFb1 },
    props: {
      paramCheck:{
        default:{},
        type:Object
      },
    },
    created(){
      if(this.paramCheck.comId){
        // //console.log("查看报表");
        this.startAccountPeriod = this.paramCheck.period
        this.comId = this.paramCheck.comId
      }
    },
    data(){
      return{
        param:{
          comIds:[this.$store.getters['user/comInfo'].comId],
          period:this.$store.getters['user/comInfo'].period,
          bookNames:['gs_whsyjsfsbbygz']
        }
      } 
    },
    methods:{
      printingNew(){
        report(this.param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        })
      },
      download(){
        exportReport(this.param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .right_box {
    // float: right;
    width:25%;
    text-align: right;
    margin-bottom: 15px;
    margin-right: 16px;
    position: absolute;
    top: 9px;
    right: 0;
    z-index: 999;
  }
 
</style>