<template>
  <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="财产和行为税纳税申报表">
      <mainTable  ref="maintable" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></mainTable>
    </el-tab-pane>
    <el-tab-pane label="印花税(附表)">
      <fbOne ref="fbOne" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></fbOne>
    </el-tab-pane>
    <el-tab-pane label="城镇土地使用税(附表)">
      <fbTwo ref="fbTwo" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></fbTwo>
    </el-tab-pane>
    <el-tab-pane label="房产税(附表)">
      <fbThree ref="fbThree" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></fbThree>
    </el-tab-pane>
    <el-tab-pane label="资源税(附表)">
      <fbFour ref="fbFour" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></fbFour>
    </el-tab-pane>
   <!--  <el-tab-pane label="环境保护税(附表)">
      <fbFive ref="fbFive" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbFive>
    </el-tab-pane> -->
  </el-tabs>
</template>

<script>
import mainTable from "./components/mainTable.vue"
import fbTwo from "./components/fb2.vue"
import fbThree from "./components/fb3.vue"
import fbOne from "./components/fb1.vue"
import fbFour from './components/fb4.vue'
import fbFive from './components/fb5.vue'
export default {
  name:'propertyTax',
  components:{
    mainTable,
    fbOne,
    fbTwo,
    fbThree,
    fbFour,
    fbFive
  },
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  data() {
    return {
      startAccountPeriod:'',
      comId:0,
      source:""
    }
  },
  created(){
    if(this.paramCheck.comId){
      this.startAccountPeriod = this.paramCheck.period
      this.comId = this.paramCheck.comId
    }else{
      if(this.$store.getters["commons/params"].type){
        this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
        this.comId = this.$store.getters["commons/params"].comId;
        this.source = "swcsh"
      }
    }
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '财产和行为税纳税申报表'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '印花税(附表)'){
        this.$refs.fbOne.getList()
      } else if(tab.props.label == '城镇土地使用税(附表)'){
        this.$refs.fbTwo.getList()
      }else if(tab.props.label == '房产税(附表)'){
        this.$refs.fbThree.getList()
      }else if(tab.props.label == '资源税(附表)'){
        this.$refs.fbFour.getList()
      }else if(tab.props.label == '环境保护税(附表)'){
        console.log(this.startAccountPeriod);
        this.$refs.fbFive.getList()
      }
    }
  }
}
</script>

<style>

</style>