<template>
  <div class="big_box4">
    <div class="top_title3">
      <h4>文化事业建设费申报表</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <el-scrollbar :height="contentStyleObj">
    <table class="content" cellspacing="0">
      <tr class="top_bg">
        <td colspan="3" class="center weight" style="width:60%;">项目</td>
        <td class="center weight" style="width:8%;">栏次</td>
        <td class="center weight" style="width:16%;">本期数</td>
        <td class="center weight" style="width:16%;">本年累计</td>
      </tr>
      <tr>
        <td rowspan="2" style="width:12%;" class="bg_color">计费收入</td>
        <td colspan="2" class="bg_color">应征收入</td>
        <td class="center bg_color">1</td>
        <qzf-td :disabled="false" v-model:amount="list.yzsrbys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.yzsrbnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">免征收入</td>
        <td class="center bg_color">2</td>
        <qzf-td :disabled="false" v-model:amount="list.mzsrbys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.mzsrbnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td rowspan="8" class="bg_color">费率计算</td>
        <td colspan="2" class="bg_color">扣除项目期初金额</td>
        <td class="center bg_color">3</td>
        <qzf-td :disabled="false" v-model:amount="list.jcxmqcjeBys" @change="changeList()"></qzf-td>
        <td class="center">----</td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">扣除项目本期发生额</td>
        <td class="center bg_color">4</td>
        <qzf-td :disabled="false" v-model:amount="list.jcxmbqfseBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.jcxmbqfseBnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td rowspan="2" class="bg_color">本期减除额</td>
        <td class="bg_color">应征收入减除额</td>
        <td class="center bg_color">5</td>
        <qzf-td :disabled="false" v-model:amount="list.yzsrjceBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.yzsrjceBnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td class="bg_color">免征收入减除额</td>
        <td class="center bg_color">6</td>
        <qzf-td :disabled="false" v-model:amount="list.mzsrjceBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.mzsrjceBnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">扣除项目期末余额</td>
        <td class="center bg_color">7=3+4-5-6</td>
        <qzf-td :disabled="false" v-model:amount="list.jcxmqmyeBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.jcxmqmyeBnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">计费销售额</td>
        <td class="center bg_color">8=1-5</td>
        <qzf-td :disabled="false" v-model:amount="list.jfxseBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.jfxsebnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">费率</td>
        <td class="center bg_color">9</td>
        <td class="center">0.03</td>
        <td class="center">----</td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">应缴费额</td>
        <td class="center bg_color">10=8×9</td>
        <qzf-td :disabled="false" v-model:amount="list.yjfeBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.yjfebnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td rowspan="9" class="bg_color">费额缴纳</td>
        <td colspan="2" class="bg_color">期初未缴费额（多为负数）</td>
        <td class="center bg_color">11</td>
        <qzf-td :disabled="false" v-model:amount="list.qcwjfeDjwfBys" @change="changeList()"></qzf-td>
        <td class="center">----</td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">本期已缴费额</td>
        <td class="center bg_color">12=13+14+15</td>
        <qzf-td :disabled="false" v-model:amount="list.bqyjfeBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.bqyjfebnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">其中：本期预缴费额</td>
        <td class="center bg_color">13</td>
        <qzf-td :disabled="false" v-model:amount="list.qzbqyjfeBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.bqyjfebnlj1" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">本期缴纳上期费额</td>
        <td class="center bg_color">14</td>
        <qzf-td :disabled="false" v-model:amount="list.qzbqjnsqfeBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.bqjnsqfebnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">本期缴纳欠费额</td>
        <td class="center bg_color">15</td>
        <qzf-td :disabled="false" v-model:amount="list.qzbqjnqfeBys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.bqjnqfebnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">期末未缴费额（多为负数）</td>
        <td class="center bg_color">16=10+11-12</td>
        <qzf-td :disabled="false" v-model:amount="list.qmwjfebys" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.qmwjfedjwfbnlj" @change="changeList()"></qzf-td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">其中：欠缴费额（≥0）</td>
        <td class="center bg_color">17=11-14-15</td>
        <qzf-td :disabled="false" v-model:amount="list.qzqjfe" @change="changeList()"></qzf-td>
        <td class="center">----</td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">本期应补（退）费额</td>
        <td class="center bg_color">18=10*(1-减征比例)-13</td>
        <qzf-td :disabled="false" v-model:amount="list.bqybtfe"></qzf-td>
        <td class="center">----</td>
      </tr>
      <tr>
        <td colspan="2" class="bg_color">本期检查已补缴费额</td>
        <td class="center bg_color">19</td>
        <qzf-td :disabled="false" v-model:amount="list.bqjcybjfe" @change="changeList()"></qzf-td>
        <qzf-td :disabled="false" v-model:amount="list.bqjcybjfeBnlj" @change="changeList()"></qzf-td>
      </tr>  
    </table>
  </el-scrollbar>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')">
    <el-button type="primary" @click="saveValues()" size="small" ><i class="iconfont icon-baocun"></i>保存</el-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'culturalMain',
  props: {
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: {},
      attachArr: [],
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      contentStyleObj:{}
    }
  },
  created() {
    this.getList()
    this.contentStyleObj = this.$getHeight(264)
  },
  methods: {
    // 公式
    changeList(){
      this.list.bqybtfe = (this.list.yjfeBys*0.5 - this.list.qzbqyjfeBys).toFixed(2)*1
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_whsyjsfsbbygz',
          itemName:'main',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_whsyjsfsbbygz',itemName:'main'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_whsyjsfsbbygz',
          itemName:'main',
          items: [this.list],
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_whsyjsfsbbygz',
          itemName:'main',
          items: [this.list]
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 13px;
    // text-align: right;
    // padding-right: 12px;
  }
}

.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 0;
  right: 3px;
  
}
</style>